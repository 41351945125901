<template>
  <div class="text">
    <h2 class="text__title">1. Why are these terms important</h2>
    <p class="text__p">These terms and conditions (“terms”) apply to the account you create with us on our mobile application or website (“Butler Account”). We’ll ask you to accept these terms before you create a Butler Account. These terms also set out other important information that you need to know for the below products and services.</p>
    <p class="text__p">You should read these terms, along with the Fees clause and Privacy Notice, which together, form a legal agreement between:</p>
    <ul class="text__list">
      <li>you, the holder of the Butler Account; and</li>
      <li>us, the Butler entity described below.</li>
    </ul>

    <h2 class="text__title">2. Who we are</h2>
    <p class="text__p"><b>Piastra Pay sp. z o.o</b> (a company incorporated in the Republic of Poland with company registration number  0000929627) and whose head office is Aleja Powstania Warszawskiego str., nr 15, places. Krakow, code 31539, post office Krakow, Poland (“Butler”). Butler  is authorised and regulated by the Central Bank of Poland as a Small Payment institution.</p>

    <br>

    <h2 class="text__title">3. Opening a Butler Account</h2>
    <p class="text__p">To use our products and services, you must first register with us by creating a Butler Account. To create a Butler Account, we’ll ask for details about you and we’ll ask you to create credentials to securely log into your Butler Account. We may ask for details about where your funds come from. We may also ask you later for updated information.</p>
    <p class="text__p">You must provide us with correct and accurate information, and you must let us know if this information changes. We ask for this information to meet our legal and regulatory obligations and to verify your identity. We may also conduct further identity checks from time to time. Our</p>
    <p class="text__p">The EEA includes all the countries in the European Union plus Iceland, Norway and Liechtenstein</p>
    <p class="text__p">Privacy Notice explains more about how we use your information for these and other purposes.</p>
    <p class="text__p">When we have the information we need, we’ll open your Butler Account and you may start using our products and services. If you don’t provide the information we ask for, you may not be able to use some or all our products or services.</p>
    <p class="text__p">We may also monitor transactions made using our services for suspicious activity and fraud. If we suspect that you are using our services in an unlawful manner or in breach of these terms, we may immediately end or suspend your use of our services.</p>
    <p class="text__p">You cannot open a Butler Account:</p>
    <ul class="text__list">
      <li>if you’re not yet 18 years of age;</li>
      <li>if you already have a Butler Account;</li>
      <li>if you previously had a Butler Account with us, which we had to close;</li>
      <li>to use it to pay for any Prohibited Activities; or</li>
      <li>if onboarding you would mean that we would break the law or any one of our policies.</li>
    </ul>
    <p class="text__p">You must not make a payment through your Butler Account for anyone else, but yourself. Your Butler Account will remain open, and these terms will apply, until you or we close your Butler Account.</p>

    <h2 class="text__title">4. Multi-Currency Account</h2>
    <p class="text__p">We can provide a payment account (“<b>Multi-Currency Account</b>”) from your Butler Account that holds electronic money (“<b>money</b>”). Electronic money is an electronic representation of cash. When we receive funds from you, we’ll issue an equivalent value of electronic money in your Multi-Currency Account. The currencies you can hold in a Multi-Currency Account will depend on where you live and what currencies we support at the time, which you will see in your Butler Account.</p>
    <p class="text__p">We may charge a fee and will exchange your money using our exchange rate at the time of the payment.</p>
    <p class="text__p">Our fees and exchange rate appear in our <b>Fees</b> clause. How we calculate our exchange rate is also detailed in the <b>Fees</b> clause.</p>
    <p class="text__p">Your Multi-Currency Account is not a bank account. We do not pay interest on any Multi- Currency Account balance. We never lend your money to anyone and as your funds are safeguarded, your funds will not be covered by a financial services compensation scheme (such as the UK Financial Services Compensation Scheme or the Central Bank of Ireland’s Deposit Guarantee Scheme).</p>

    <h2 class="text__title">5. Funding your Multi-Currency Account</h2>
    <p class="text__p">You can deposit money into your Multi-Currency Account by using any of the funding methods that appear in the Butler website (such as a bank transfer or a card). If you fund your Multi-Currency Account by selecting your account provider from your Butler Account, and without inputting ours or your account details on your account provider’s interface, you’re giving permission to an open banking provider to initiate a payment for you from another account you hold. This provider is authorised by a regulator to provide this service and after you confirm the payment, you’ll be asked to agree to their terms of service and be provided with their details. When you authorise an open banking provider to initiate a payment for you, we must provide access to aspects of your Butler Account if they need it to initiate your payment.</p>
    <p class="text__p">We’ll credit your Multi-Currency Account once we receive the money or our third party partners confirm those funds are available. If we do not receive the funds or it does not reach us within a reasonable time, we may reverse that credit. If you don’t have enough money to reverse that credit, you must pay us back as soon as we ask for it.</p>

    <h2 class="text__title">6. Times when we cannot make a payment</h2>
    <p class="text__p">There are times when we may not be able to make a payment at all or your payment may be delayed. These cases include if:</p>
    <ul class="text__list">
      <li>you do not have enough money in your Multi-Currency Account (and you opted to make a payment using your Multi-Currency Account);</li>
      <li>the card or account you</li>
      <li>you have reached a spending limit;</li>
      <li>you did not give us enough information to process the payment;</li>
      <li>making the payment would cause us to break the law or these terms;</li>
      <li>you owe us money;</li>
      <li>if a bankruptcy order is made against you or you’ve entered into an individual voluntary arrangement with your creditors;</li>
      <li>we have requested important information from you and you have not provided this information to us;</li>
      <li>use to deposit funds refuses to fund a payment (for example, if you don’t have enough funds or the account provider has suspended the account);</li>
      <li>you have broken these terms;</li>
      <li>a third party prevents us from making the payment;</li>
      <li>you account has been suspended; or</li>
      <li>we need to do further checks about you to comply with our regulatory obligations and you have not given us enough information.</li>
    </ul>

    <h2 class="text__title">7. Communicating with you</h2>
    <p class="text__p">We’ll send you service notifications or other information about your Multi-Currency Account by email, text or through your Butler Account.</p>
    <p class="text__p">When you make a Transfer, we’ll send you information (such as the payment date, payment reference and conversion rate) about a Transfer.</p>
    <p class="text__p">If you have a Multi-Currency Account, you can check any payments that go in and out of your Multi-Currency Account through your Butler Account at any time. While you’re our customer, you can download a statement from your Butler Account containing any payments that went into and out of your Multi-Currency Account, but you should also store these statements locally so you have access to this information if you or we close your Butler Account or you cannot access your Butler Account. We’ll not make any changes to this information.</p>
    <p class="text__p">We’re not responsible for any losses you suffer as a result of us refusing or delaying a payment.</p>
    <ul class="text__list">
      <li>your contact details in your Butler Account must be accurate and up-to-date. You can update your details in your Butler Account at anytime or you can contact us;</li>
      <li>for push notifications, you must give us permission to send you push notifications in your phone settings; or</li>
      <li>for certain payment features, the sender must have entered your details correctly.</li>
    </ul>
    <p class="text__p">This information will always be available to you in English and depending on your preferred language, we may also be able to offer this information to you in your preferred language.</p>

    <h2 class="text__title">8. How to keep your Butler Account  safe</h2>
    <p class="text__p">We do everything we can to keep your Butler Account safe. We ask you to do the same by keeping the security details to your Butler Account safe.</p>
    <p class="text__p">This means you shouldn't keep your passcode or any device you use to access your Butler Account, and you should disguise or protect them if you write them down or store them. Don’t share your passcode or security details with anyone other than an open-banking provider who is acting in line with regulatory requirements.</p>
    <p class="text__p">Here are some other steps you should take to keep your Butler Account safe:</p>
    <ul class="text__list">
      <li>keep your Butler Account information and any device that accesses your Butler Account safe;</li>
      <li>log out of your Butler Account after using our products and services;</li>
      <li>close down our mobile application when you're not using your Butler Account;</li>
      <li>keep the factors that you use to log into your Butler Account (like your sim card or phone number) secure and don't let other people use them; and</li>
      <li>as soon as they’re available, download the latest version of our mobile application or any software for the device you are accessing your Butler Account from.</li>
    </ul>
    <p class="text__p">You must contact us immediately from your Butler Account (by clicking on your profile icon on the top right of your Butler Account and clicking Support) or by email at info@Butler.pl if the security details to your Butler Account could have been used without your permission. If we suspect fraud, security or other suspicious activities we’ll contact you by email.</p>
    <p class="text__p">You must always check that you’ve entered the correct details for the person you want to pay before you make a payment. Here are a few ideas to think about before you make a payment:</p>
    <ol class="text__list">
      <li>make sure you know the person you are making a payment to. If someone approaches you and asks you to make a payment to them, but you are not sure who they are or what the payment is for, you may be a victim of a scam and we may not be able to recover the funds for you;</li>
      <li>the contacts you see in the Butler Account are taken from the names and phone numbers you have saved to your own phone. These names and numbers are not verified by us or anyone else. This means that if you have saved the wrong number or wrong name to your phone, or someone else now has that number, you’ll pay the wrong person and may lose your money.</li>
    </ol>
    <p class="text__p">We’re not responsible if we make a payment to the person you tell us to, even if you gave us the wrong account number, card details or phone number by mistake. However, if you ask us to, we’ll try to get your funds back for you. We may also try to get you information about the beneficiary so that you can try to get it back yourself (if the law allows us to). While we’ll try to do these things, we don’t guarantee that we'll, and in some cases we won’t, be able to recover the funds.</p>
    <p class="text__p">If you don’t follow these steps to keep your Butler Account safe, we may not be able to refund payments made using your Butler Account, even if you did not make those payments.</p>

    <h2 class="text__title">9. Unauthorized or incorrect payments</h2>
    <p class="text__p">If you think a payment in your Butler Account was not made by you or was incorrectly made, you must contact us as soon as possible, and no later than 13 months after the payment. Unless we can show that you authorised the payment, we’ll refund the payment to you and our charges that you paid as a result of the payment. If you contact us after 13 months, we may not be able to refund the payment. You should tell us in all cases where you believe that there is an unauthorised or incorrectly executed transaction that involves your Butler Account, even if you have also notified other companies.</p>
    <p class="text__p">If you suspect an unauthorised or incorrect payment please contact us immediately from your Butler Account (by clicking on your profile icon on the top right of your Butler Account and clicking Support) or by email at help@Butler.pl</p>
    <p class="text__p">We’ll refund you, if:</p>
    <ul class="text__list">
      <li>the payment was taken after you told us that your security details or Butler Account had been lost, stolen or compromised</li>
      <li>we haven’t provided a way for you to tell us your security details or 7 Pay Account details have been lost or stolen; or</li>
      <li>we haven’t applied security procedures that we are required to use to verify your identity when authorising a payment.</li>
    </ul>
    <p class="text__p">These rules don’t apply to pure currency exchanges in your Multi-Currency Account (in other words, when you’re just exchanging currencies and not making a payment). You’ll not be entitled to a refund if you intentionally or with gross negligence compromised the security of your Butler Account  or if we suspect that you acted fraudulently. If we believe you’ve been fraudulent, we may notify the local authorities.</p>
    <p class="text__p">If you ask us to, we’ll always try to trace an unauthorised or defective payment and will let you know what we find.</p>
    <p class="text__p">On the other hand, if a payment is mistakenly paid into your Butler Account, we may reverse the payment or put a hold on it. We can do this even if you’ve spent part of the payment and you’ll have to pay the amount spent back to us when we ask for it. If the person who mistakenly made the payment makes a legal claim to get it back themselves, we may need to share your information with them.</p>
    <p class="text__p">For these reasons, you should always check your Butler Account regularly to make sure everything is correct.</p>

    <h2 class="text__title">10. How long does it take for us to make a payment?</h2>
    <p class="text__p">We want your money to reach the recipient quickly and safely.</p>
    <p class="text__p">Most payments will reach the bank or account provider of the recipient within the timeframes set out in the table below. However, in some circumstances, we may need longer to process your payment (for example, when we suspect fraud)</p>
    <p class="text__p">The execution times above are the times that it will take for the bank or account provider of the recipient to receive the funds. This does not mean that the bank or account provider will credit the recipient’s account within these timeframes. We’re not responsible for any delays by the recipient’s account provider to credit the payment.</p>

    <h2 class="text__title">11. Other ways we keep your money safe</h2>
    <p class="text__p">We partner with financial institutions that hold the funds of customers separately to our own funds in accordance with applicable regulations. This is known as safeguarding. As your funds are safeguarded, if we were to become insolvent, once any costs related to any insolvency are paid out, you (and all our other customers) would be paid out from any accounts safeguarded by our financial institution partner before anyone else. This process would be handled by an insolvency practitioner, not by us.</p>
    <p class="text__p">We may also need to suspend or block any of our services to you:</p>
    <ul class="text__list">
      <li>to comply with our regulatory obligations, or if we believe we’d be in breach if we did not suspend or block our services to you;</li>
      <li>to investigate any unauthorised access or suspicious or unusual activity;</li>
      <li>if we have reasonable security concerns, such as if we believe your authorisation credentials have been misused or compromised;</li>
      <li>if we suspect, or there has been, any fraudulent or criminal activity; or</li>
      <li>if doing so, helps us protect the security and integrity of our operating systems.</li>
    </ul>
    <p class="text__p">We'll let you know beforehand through your Butler Account, or as soon as possible after, if we suspend or block any of our services. We'll let you know why we've done it (unless telling you would be unlawful or would reduce yours or our security). While your Butler Account</p>
    <p class="text__p">is suspended or blocked, you’ll not be able to make any payments. We’ll reinstate your access once the reasons for suspension are resolved (unless it would be unlawful to do so).</p>

    <h2 class="text__title">12. Closing your Butler Account</h2>
    <p class="text__p">You can close your Butler Account by contacting us any time (see How to contact us section below). Closing your Butler Account means your agreement with us will end.</p>
    <p class="text__p">We’ll give you two months’ notice if we need to close your Multi-Currency Account due to any lengthy period of inactivity.</p>
    <p class="text__p">We may also need to close your Butler Account immediately and without first letting you know:</p>
    <ul class="text__list">
      <li>if you breach our agreement in any material way;</li>
      <li>to comply with our regulatory obligations;</li>
      <li>if we suspect, or there has been, any fraudulent or criminal activity; or</li>
      <li>if doing so, helps us protect the security and integrity of our operating systems.</li>
    </ul>
    <p class="text__p">If you or we close your Butler Account, we may ask you to withdraw any money in your Multi-Currency Account within a certain period of time. If you do not withdraw your money within this period, you’ll still be able to withdraw your balance for six years from the time your Butler Account is terminated by contacting us.</p>
    <p class="text__p">If there are pending payments you’d like us to make and we’re lawfully allowed to do so, we’ll make those payments. Once done, we’ll stop your access and your Butler Account will be permanently closed.</p>

    <h2 class="text__title">13. When we can change these terms</h2>
    <p class="text__p">We'll only change these terms:</p>
    <ul class="text__list">
      <li>if we think it’ll make the terms easier to understand;</li>
      <li>to reflect any changes in the way our business or products are run (for example, to cater for technological improvements or new partner requirements);</li>
      <li>to reflect legal or regulatory requirements that apply to us;</li>
      <li>to reflect changes in the cost of running our business; or</li>
      <li>because we’re updating or introducing a new service or product feature.</li>
    </ul>
    <p class="text__p">For changes related to the existing payment service aspects of your Multi-Currency Account, including our pricing, spending limits and instructions on how to pay and to keep your Multi-Currency Account safe, we'll give you two months' notice before we make any change (unless the law requires that we implement the change earlier). We'll treat you as having accepted the change unless you tell us that you want to close your Multi-Currency Account before the change is made. You’ll not be charged any fee for closing your Multi-Currency Account.</p>
    <p class="text__p">Exchange rates that we set change all the time and without notices. Therefore, we’ll not notify you if there has been a change in our exchange rate.</p>
    <p class="text__p">In all other cases, including in relation to our other products and services and other aspects of your Butler Account, we’ll let you know the terms for a new feature or a change in the service before you use it or it applies, and those terms will apply immediately.</p>

    <h2 class="text__title">14. How to contact us or make a complaint</h2>
    <p class="text__p">The quickest way to contact us is by using the chat feature in your Butler Account. Simply open your Butler Account and click Support. Then select to chat to us or read our FAQs. You can also start a conversation with us sending an email to us at info@Butler.pl</p>
    <p class="text__p">If you’re unhappy with our service, please contact us first so we can try to make things right. We may ask you for more information about you and your Butler Account to make sure that we’re speaking to you.</p>
    <p class="text__p">We’ll consider any formal complaint you send. If you wish to make a formal complaint, you can send an email to us at info@Butler.pl.  If you tell us that you’d like to complain, we may submit a formal complaint on your behalf if you ask us to. Please let us know what you’re unhappy about, when the issue began and how we can make things right, along with your full name and contact details.</p>
    <p class="text__p">We'll look into your complaint and acknowledge your complaint within 5 working days. Our final response to your complaint, or a written response explaining why the final response has not been completed, will be emailed to you within 15 working days after you make your complaint, or in some circumstances, within 7 weeks (and we’ll let you know if this is the case).</p>
    <p class="text__p">We’ll always communicate with you in English.</p>
    <p class="text__p">If you’re not happy with our resolution you may be able to refer your complaint directly to an out-of-court dispute resolution authority. The authority you complain to depends on where you live and what you’re complaining about. You have the right to apply to any competent court if you think we have breached the law.</p>

    <h2 class="text__title">15. Other legal bits</h2>
    <p class="text__p">We won’t be responsible for any loss or damage that is not reasonably foreseeable, or that’s caused by your failure to comply with these terms. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you started using our services, both we and you knew it might happen. Nothing in these terms removes or limits our liability for death or personal injury resulting from our negligence or from fraud or fraudulent claims and statements.</p>
    <p class="text__p">If these terms are translated into another language, the translation is for reference only and the English version will apply. If English is not your usual language, you must communicate with us in the English language in relation to any legal matters arising under these terms, including with respect to submitting and resolving any complaints.</p>
    <p class="text__p">All the intellectual property in our products and services (for example, the Butler Account, the content in our mobile application and our website, our product names, our logo and card designs) are owned by Piastra Pay sp. z o.o.(a company incorporated under the laws of the Republic of Poland with company number 0000929627) and are used by us and other companies which are affiliated with Piastra Pay sp. z o.o.. You must not use our intellectual property as your own, except to enjoy our services. You also must not reverse-engineer any of our services (that is, reproduce them after examining how our services are constructed).</p>
    <p class="text__p">This agreement is between you and us. Only you and we have rights under the agreement. You cannot transfer any of your rights or obligations to anyone. If one part of this agreement is invalid, the other parts of the agreement are still valid. We may transfer any of your and our rights or obligations under the agreement in response to a legal or regulatory requirement or if we reasonably think that this won't have a significant negative effect on your rights under the agreement. A transfer of rights and obligations is known as ‘novation’. A transfer of rights only is called an ‘assignment’.</p>
    <p class="text__p">These terms do not apply to your relationship with your device provider or any platform that allows you to access our products and services. Your device provider is not responsible for any content nor any support or maintenance services for our products and services. If our products and services do not meet any standards or other promises, your device provider will not be responsible for any claims you have in relation to our products and services (to the maximum extent permitted by law).</p>
    <p class="text__p">As your device provider is a third party beneficiary to these terms, they may be permitted to enforce these terms. We (and not your device provider) are responsible for investigating or dealing with any third party intellectual property claims in relation to our products and services. You also acknowledge that you’re not text__listed on any US government prohibited or restricted party text__list nor are you located in a country that is subject to a US government embargo or that has been designated by the US government as a “terrorist supporting country”.</p>

    <h2 class="text__title">Exchange Rate and Fees</h2>
    <p class="text__p">If you make a Transfer in a different currency to the one you used to fund a payment, we’ll need to exchange your money.</p>
    <p class="text__p">Our exchange rate is based on many factors including the rate we’re charged, the mid-market rate (which is the midpoint between the buy and sell prices of any two currencies) and the rates of our third-party partners. We may also include a mark-up in our exchange rate. As there are outside factors that influence our rates, the rate we apply can change daily and when they do those changes will be made right away.</p>
    <p class="text__p">We’re very transparent about our exchange rates. In your Butler Account, we’ll always tell you of the total cost, including our exchange rate and any additional fees (see below) before you make a Transfer. The exchange rate that we disclose to you before you make a payment forms part of your agreement with us.</p>
    <h2 class="text__title">Fees</h2>
    <p class="text__p">We’re very transparent about our fees. In your Butler Account, we’ll always tell you of any fees that apply to a Transfer before you make the payment. The fee that we disclose to you before you make a Transfer forms part of your agreement with us.</p>
    <p class="text__p">Typically, though, depending on your funding currency, we’ll charge a fee of:</p>
    <ul class="text__list">
      <li>£1/€1.5/4.9PLN  when we exchange your money from one currency to another; or</li>
      <li>the higher of either £1/€1.5/7PLN or 1% of the payment amount when transferring money in the same currency.</li>
    </ul>
    <p class="text__p">The exchange rate and fee that you agree to before you make a payment form part of your agreement with us. When we charge a fee or exchange your money, it’ll be based on the exchange rate on the day of the payment. The fee and exchange rate that is applied to your payment will also appear in the transaction history in your Butler Account</p>

    <h2 class="text__title">Prohibited Activities</h2>
    <ol class="text__list">
      <li>Adult entertainment;</li>
      <li>Drug paraphernalia;</li>
      <li>Drug sales</li>
      <li>Internet gun sales;</li>
      <li>Sexual encounter firms;</li>
      <li>Unlicensed money transmitters;</li>
      <li>Ammunition sales;</li>
      <li>Escort services;</li>
      <li>Firearms;</li>
      <li>Government grants;</li>
      <li>Home based charities (other than any specified charities that we allow from time to</li>
      <li>time);</li>
      <li>Payday loans;</li>
      <li>Pornography;</li>
      <li>Tobacco sales;</li>
      <li>Shell banks;</li>
      <li>Affiliate marketing;</li>
      <li>Contextual advertising.</li>
    </ol>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';

  @Component({
    page: {
      title: 'documents.terms',
    },
  })
  export default class Terms extends Vue {}
</script>

<style lang="scss" scoped></style>
