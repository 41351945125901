<template>
  <div class="text">
    <h2 class="text__title">What are cookies?</h2>
    <p class="text__p">Our website uses cookies, that’s why when you continue to use it you approve with the conditions of this Cookie Policy.</p>
    <p class="text__p">Cookies are small text files or code that are placed on your computer (or different devices like a laptop, smartphone or tablet) or web-browser and help us to ensure the proper and safe operation of the website. Cookies allow to collect the information about the services that interest Users of the website, and then consider useful visiting navigation.</p>

    <h2 class="text__title">How do we use cookies?</h2>
    <p class="text__p">Cookies is used for:</p>
    <ol class="text__list">
      <li>To configure the content of the website in accordance with the preferences of the User, as well as to recognize the device of the User and view the internet page, designed to the User individual needs;</li>
      <li>To develop statistics, which helps to understand how Users use the website, thereby improving its structure and content;</li>
      <li>To support session of the User of the website (after the enter to the website), thereby Users should not be repeat to enter username and password on each page.</li>
    </ol>

    <h2 class="text__title">Types of cookies.</h2>
    <p class="text__p">We use different types of cookies:</p>
    <ol class="text__list">
      <li>Strictly necessary cookies needed to operate the website correctly, moved about the website and utilized its facilities. These files have memorized previous actions (for example, text that you entered) at the transition back at the page at the same session. If you will not agree to use this type of file this may affect on the productivity of the website or its components.</li>
      <li>Operational cookies help us to understand how visitors interact with the website providing information about the areas you visited and the amount of time you spent on the website, they also show problems in the work of the internet resource for example error messages. This will help us to improve the operation of the website. This type of cookies can not be used for your identification. All information that is collected and analyzed is anonymous.</li>
      <li>Functionality cookies are used to remember that you have previously visited the website and the choices that you have made (for example, have entered your username, language and country, where you are), to make the work with the website effective and individual.</li>
      <li>Advertising cookies used to provide the content that may be the most interesting for you. This type of cookies can be used for sending you measured advertising or with the aim of limiting visibility of advertising. We can use this cookie for capturing the information about the websites which you have visited and share this information with other clients, including advertisers and agencies. We are not in control and can not access third-party advertising cookies or information which they contain. A third-party advertising server manages any information which it contains with the help of cookies which it is placed in accordance with its Privacy Policy.</li>
    </ol>

    <h2 class="text__title">How can you control or delete cookies?</h2>
    <p class="text__p">Most Internet browsers are initially configured to automatically accept cookies. You can change the settings of your browser in such a way as to block cookies or to warn when files of this type are sent to the device. If you use different devices to view and access the website (for example, computer, laptop, smartphone or tablet) you need to make sure that each browser on each device is configured according to your point of view on working with cookies.</p>

    <h2 class="text__title">Cookies in emails.</h2>
    <p class="text__p">Our emails include cookies that track whether you open emails from us or use any links in them. Cookies used in our emails for monitoring these actions are unique for every email and not stored on your computer or other devices.</p>

    <h2 class="text__title">Contact Us.</h2>
    <p class="text__p">If you have any questions about our use of cookies, please contact us:</p>
    <p class="text__p">info@butler.com</p>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';

  @Component({
    page: {
      title: 'documents.cookiepolicy',
    },
  })
  export default class CookiePolicy extends Vue {}
</script>

<style lang="scss" scoped></style>
