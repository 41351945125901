<template>
  <div class="text">
    <h2 class="text__title">1. Processing of Personal Data</h2>
    <p class="text__p">The responsible data controller for the website https://butler.com/ is Piastra Pay sp. z o.o (a company incorporated in the Republic of Poland with company registration number  0000929627) and whose head office is Aleja Powstania Warszawskiego str., nr 15, places. Krakow, code 31539, post office Krakow, Poland, and email info@butler.com</p>
    <p class="text__p">The data controller has appointed a data protection officer, whose contact details are: email: dpo@butler.com</p>

    <h2 class="text__title">2.  Types of Processed Personal Data:</h2>
    <ul class="text__list">
      <li>Name, phone number, and email address;</li>
      <li>Delivery address of goods;</li>
      <li>Bank account number;</li>
      <li>Cost of goods and services, and payment-related data (purchase history);</li>
      <li>Customer support data.</li>
    </ul>

    <h2 class="text__title">3.   Purposes of Processing Personal Data:</h2>
    <p class="text__p">Personal data is used for managing customer orders and delivering goods. Purchase history data (purchase date, goods, quantity, customer data) is used for compiling an overview of purchased goods and services and analyzing customer preferences. The bank account number is used for refunding payments to the customer. Personal data such as email, phone number, and customer name is processed to resolve issues related to the provision of goods and services (customer support). The user's IP address or other network identifiers are processed for providing the website as an information society service and for generating web usage statistics.</p>

    <h2 class="text__title">4.    Legal Basis:</h2>
    <p class="text__p">The processing of personal data is carried out for the purpose of fulfilling the contract with the customer. The processing of personal data is carried out to fulfill legal obligations (e.g., accounting and consumer dispute resolution).</p>
    <p class="text__p">Recipients to Whom Personal Data is Disclosed:</p>
    <p class="text__p">Personal data is disclosed to the website's customer support for managing purchases and purchase history and resolving customer issues. Name, phone number, and email address are disclosed to the transport service provider chosen by the customer. If the goods are delivered by a courier, the customer's address is also disclosed in addition to the contact details. If the website's accounting is carried out by a service provider, personal data is disclosed to the service provider for accounting operations. Personal data may be disclosed to information technology service providers if necessary for ensuring the functionality of the website or data hosting.</p>

    <h2 class="text__title">5.   Security and Access to Data:</h2>
    <p class="text__p">Personal data is stored on servers of alve.ee (veebimajutus.ee) located in the territory of a European Union member state or a country in the European Economic Area. Data may be transferred to countries whose level of data protection has been assessed as adequate by the European Commission and to US companies that have joined the Privacy Shield framework. Access to personal data is limited to employees of the online store who need to access the data to resolve technical issues related to the use of the online store and to provide customer support. The online store implements appropriate physical, organizational, and information technology security measures to protect personal data from accidental or unlawful destruction, loss, alteration, unauthorized access, and disclosure. The transfer of personal data to authorized processors of the online store (e.g., transport service provider and data hosting) is based on agreements concluded between the online store and authorized processors. Authorized processors are required to implement appropriate protection measures when processing personal data.</p>

    <h2 class="text__title">6.    Access and Correction of Personal Data:</h2>
    <p class="text__p">Personal data can be accessed and corrected in the user profile of the online store. If a purchase is made without a user account, personal data can be accessed through customer support.</p>
    <p class="text__p">Withdrawal of Consent:</p>
    <p class="text__p">If the processing of personal data is based on the customer's consent, the customer has the right to withdraw consent by notifying customer support by email.</p>
    <p class="text__p">Retention:</p>
    <p class="text__p">Processing of personal data is carried out in accordance with the purpose of processing:</p>
    <p class="text__p">Purpose of processing – security and safety</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – according to deadlines specified in the law</li>
    </ul>
    <p class="text__p">Purpose of processing – order processing</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – 3 months.</li>
    </ul>
    <p class="text__p">Purpose of processing – ensuring the functioning of e-shop services</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – 3 months</li>
    </ul>
    <p class="text__p">Purpose of processing – customer management</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – 3 months</li>
    </ul>
    <p class="text__p">Purpose of processing – financial activities, accounting</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – according to deadlines specified in the law</li>
    </ul>
    <p class="text__p">Purpose of processing – marketing</p>
    <ul class="text__list">
      <li>Maximum retention period for personal data – 12 months</li>
    </ul>
    <p class="text__p">Deletion: To delete personal data, contact customer support by email. The request for deletion will be answered no later than within one month, specifying the period for the deletion of data.</p>
    <p class="text__p">Transfer: For requests for the transfer of personal data submitted by email, a response will be provided within one month. Customer support will verify the identity and inform about the data to be transferred.</p>
    <p class="text__p">Direct Marketing Notices: The email address and phone number are used for sending direct marketing notices if the customer has given the relevant consent. If the customer does not wish to receive direct marketing notices, they must select the corresponding link in the footer of the email or contact customer support. If personal data is processed for the purpose of direct marketing (profiling), the customer has the right to object to the processing of their personal data for both initial and further processing, including profiling related to direct marketing, at any time by notifying customer support by email.</p>
    <p class="text__p">Dispute Resolution: Disputes related to the processing of personal data are resolved through customer support info@butler.com.</p>
    <p class="text__p">The supervisory authority is the Republic of Poland Data Protection Inspectorate:</p>
    <br>
    <p class="text__p">The President of the Office for Personal Data Protection.</p>
    <p class="text__p">Office of the President for Personal Data Protection</p>
    <p class="text__p">Urzad Ochrony Danych Osobowych</p>
    <p class="text__p">Stawki 2</p>
    <p class="text__p">00-193 Warsaw</p>
    <p class="text__p">Poland</p>
    <br>
    <p class="text__p">Tel. +48 22 531 03 00</p>
    <p class="text__p">Fax +48 22 531 03 01</p>
    <p class="text__p">kancelaria@uodo.gov.pl</p>
    <br>
    <p class="text__p">Helpline (in Polish only): phone no. +48 606-950-000 is open from Monday to Friday from 10 am to 2 pm.</p>
    <p class="text__p">The Office of the President is open from Monday to Friday from 8 am to 4 pm.</p>
  </div>
</template>

<script>
  import { Component, Vue } from 'vue-property-decorator';

  @Component({
    page: {
      title: 'documents.policy',
    },
  })
  export default class PrivacyPolicy extends Vue {}
</script>

<style lang="scss" scoped></style>
